import React from "react";
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import { Link } from "react-router-dom";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import "../elements/styles.css";
import Footer from "../component/footer/Footer";
import Slider from "react-slick";
import PortfolioMasonry from "../elements/portfolio/PortfolioMasonry";
import { slickDot, portfolioSlick2 } from "../page-demo/script";

const Portfolio_image = (
  <img
    src="/assets/images/portfolio/portfolio-static-01.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image2 = (
  <img
    src="/assets/images/portfolio/portfolio-static-02.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image3 = (
  <img
    src="/assets/images/portfolio/portfolio-static-03.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image4 = (
  <img
    src="/assets/images/portfolio/portfolio-static-04.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image5 = (
  <img
    src="/assets/images/portfolio/portfolio-static-05.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image6 = (
  <img
    src="/assets/images/portfolio/portfolio-static-06.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image7 = (
  <img
    src="/assets/images/portfolio/portfolio-static-07.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image8 = (
  <img
    src="/assets/images/portfolio/portfolio-static-08.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image9 = (
  <img
    src="/assets/images/portfolio/portfolio-static-09.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image10 = (
  <img
    src="/assets/images/portfolio/portfolio-static-01.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image11 = (
  <img
    src="/assets/images/portfolio/portfolio-static-08.jpg"
    alt="React Creative Agency"
  />
);
const Portfolio_image12 = (
  <img
    src="/assets/images/portfolio/portfolio-static-05.jpg"
    alt="React Creative Agency"
  />
);

const list = [
  {
    image: Portfolio_image,
    category: "Development",
    title: "Graphics Design",
    description: "Lorem ipsum dolor sit amet, conse ctetur adipiscing elit.",
    link: "/portfolio-details-creative",
  },
  {
    image: Portfolio_image2,
    category: "Product Design",
    title: "Branding",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
    link: "/portfolio-details-branding",
  },
  {
    image: Portfolio_image3,
    category: "Application",
    title: "Digital",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
    link: "/portfolio-details-digitalPrint",
  },
  {
    image: Portfolio_image4,
    category: "Application",
    title: "Photography",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
    link: "/portfolio-details-photography",
  },
  {
    image: Portfolio_image5,
    category: "Application",
    title: "Event Management",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
    link: "/portfolio-details-eventManagement",
  },
  {
    image: Portfolio_image6,
    category: "Application",
    title: "Interior",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
    link: "",
  },
  {
    image: Portfolio_image7,
    category: "Application",
    title: "Digital Marketing",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
    link: "/portfolio-details-digitalMarketing",
  },
  {
    image: Portfolio_image8,
    category: "Application",
    title: "Web Design",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
    link: "/portfolio-details-web-design",
  },
  {
    image: Portfolio_image9,
    category: "Application",
    title: "Photoshop Design",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
    link: "/portfolio-details",
  },
  {
    image: Portfolio_image10,
    category: "Application",
    title: "Photoshop Design",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
    link: "/portfolio-details",
  },
  {
    image: Portfolio_image11,
    category: "Application",
    title: "Photoshop Design",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
    link: "/portfolio-details",
  },
  {
    image: Portfolio_image12,
    category: "Application",
    title: "Photoshop Design",
    description: "Lorem ipsum dolor sit amet, consec tetur adipiscing elit.",
    link: "/portfolio-details",
  },
];

const PortfolioList2 = [
  {
    image: "image-1",
    category: "Development",
    title: " Getting tickets to the big show",
  },
  {
    image: "image-2",
    category: "Development",
    title: " Getting tickets to the big show",
  },
  {
    image: "image-3",
    category: "Development",
    title: " Getting tickets to the big show",
  },
  {
    image: "image-4",
    category: "Development",
    title: " Getting tickets to the big show",
  },
  {
    image: "image-3",
    category: "Development",
    title: " Getting tickets to the big show",
  },
  {
    image: "image-4",
    category: "Development",
    title: " Getting tickets to the big show",
  },
];

const Portfolio = () => {
  return (
    <>
    <div className="active-dark bg_color--10">
      <PageHelmet pageTitle="Portfolio" />

      {/* Start Header Area  */}
      <Header
        headertransparent="header--transparent"
        colorblack="color--black"
        logoname="logo.png"
      />
      {/* End Header Area  */}

      {/* Start Breadcrump Area */}
      <Breadcrumb title={"Portfolio"} />
      {/* End Breadcrump Area */}

      {/* Start Page Wrapper  */}
      <main className="page-wrapper">
        {/* Start Portfolio Area */}
        <div className="rn-portfolio-area active-dark bg_color--10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <br></br>
                  <span className="subtitle">Goal-Oriented</span>
                  <h2 className="title">Our Works</h2>
                  <p className="description">
                    We are focused on reaching a specific objective or
                    accomplishing a given task, <br /> Have a look of projects
                    done by us
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="wrapper plr--20">
            <div className="row">
              <PortfolioMasonry
                item="8"
                column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover"
              />
            </div>
            {/* <div className="row">
                            <div className="col-md-12">
                                <div className="view-more-btn mt--60 text-center">
                                    <a className="btn-default" href="/portfolio"><span>View More Project</span></a>
                                </div>
                            </div>
                        </div> */}
          </div>
        </div>
        {/* End Portfolio Area */}

        {/* Start portfolio Area  */}

        {/* End portfolio Area  */}

        {/* Start Portfolio Area */}
        <div className="portfolio-area active-dark bg_color--10">
          <div className="rn-slick-dot">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  {/* <div className="slick-space-gutter--15 slickdot--20">
                                        <Slider {...slickDot}>
                                            {list.map((value , index) => (
                                                <div className="single_im_portfolio" key={index}>
                                                    <div className="im_portfolio">
                                                        <div className="thumbnail_inner">
                                                            <div className="thumbnail">
                                                                <Link to="/portfolio-details">
                                                                    {value.image}
                                                                </Link>    
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <div className="inner">
                                                                <div className="portfolio_heading">
                                                                    <div className="category_list">
                                                                        <Link to="/portfolio-details">{value.category}</Link>
                                                                    </div>
                                                                    <h4 className="title"><Link to="/portfolio-details">{value.title}</Link></h4>
                                                                </div>
                                                                <div className="portfolio_hover">
                                                                    <p>{value.description}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <Link className="transparent_link" to="/portfolio-details"></Link>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}
      </main>
      {/* End Page Wrapper  */}
      <div>
        <div className="watsapp ">
          <WhatsAppWidget
            companyName="Colourstreak"
            message="Hello! 👋🏼 \n\n What can we do for you?... please mention your name bellow"
            phoneNumber="7980605030"
          />
        </div>
      </div>

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}
        style={{
          position: 'fixed',
          bottom: 91,
          right: 32,
          cursor: 'pointer',
          transitionDuration: '0.2s',
          transitionTimingFunction: 'linear',
          transitionDelay: '0s',
          background: '#fd4766'
        }}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}

      {/* Start Footer Area  */}
      <Footer />
      {/* End Footer Area  */}
      </div>
    </>
  );
};

export default Portfolio;
