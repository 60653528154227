import React, { Component } from "react";
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
import "./styles.css";
const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

class PortfolioEventManagement extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
           <div className="active-dark bg_color--10">
        <PageHelmet pageTitle="Event Management" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 pb_md--100 pb_sm--100  bg_image bg_image--4"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    Event Management
                  </h2>
                  <p>
                  Event management involves overseeing all logistics leading up to and during an event.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Details */}
        <div className="rn-portfolio-details ptb--120 active-dark bg_color--10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="row row--35 mb--50">
                    <div className="col-lg-6">
                      <div className="inner">
                        <div className="section-title">
                          <h2 className="title">Event Management</h2>
                          <p className="description mt--30">
                            Events play a huge part in the success of a brand or
                            an organization.
                          </p>
                          <p className="description">
                            We plan and organize promotional, business and
                            social events. We are responsible for running a
                            range of events, ensuring the target audience is
                            engaged and the message of the event is marketed
                            properly. We'll manage the whole process from the
                            planning stage, right through to running the event
                            and carrying out the post-event evaluation.
                          </p>
                        </div>
                        {/* <div className="portfolio-view-list d-flex flex-wrap">
                                                    <div className="port-view">
                                                        <span>Branch</span>
                                                        <h4>Ability</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Project Types</span>
                                                        <h4>Website</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Program</span>
                                                        <h4>View Project</h4>
                                                    </div>
                                                </div> */}
                        <div className="portfolio-details-btn mt--30">
                          <a className="btn-default btn-border" href="/contact">
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                        <div className="inner">
                          <div className="section-title">
                            <h4 className="title">Our Services</h4>
                            {/* <p className="description mt--30">
                              Our skilled and experienced team of graphic
                              designers and print technicians understand the
                              importance of quality and consistency in every job
                              and that is why we are always on hand and happy to
                              help with print guidelines
                            </p>
                            <p className="description mt--30">
                              artwork setup and all things in between to make
                              sure your order is as good as it can be. .
                            </p> */}
                            <ul className="list-style--1">
                              <li>
                                <FiCheck /> Institutional Event
                              </li>
                              <li>
                                <FiCheck /> Corporate Event
                              </li>
                        
                              <li>
                                <FiCheck /> Product Promotional Event
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                      <img
                        src="/assets/images/portfolio/Event/01.jpg"
                        alt="Portfolio Images"
                      />
                      {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button> */}
                    </div>

                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Event/02.jpg"
                        alt="Portfolio Images"
                      />
                    </div>

                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Event/03.jpg"
                        alt="Portfolio Images"
                      />
                    </div>

                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Event/04.jpg"
                        alt="Portfolio Images"
                      />
                    </div>

                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Event/05.jpg"
                        alt="Portfolio Images"
                      />
                    </div>

                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Event/06.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Event/07.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Event/08.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Event/09.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Event/10.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="thumb">
                      <img
                        src="/assets/images/portfolio/Event/11.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Details */}

        {/* Start Related Work */}
        <div className="portfolio-related-work pb--120 active-dark bg_color--10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle">Related Work</span>
                  <h2 className="title">Our More Projects</h2>
                </div>
              </div>
            </div>
            <div className="row mt--10">
              {/* Start Single Portfolio */}
              <div className="col-lg-6 col-md-6 col-12">
                <div className="related-work text-center mt--30">
                  <div className="thumb">
                    <a href="/portfolio-details-branding">
                      <img
                        src="/assets/images/portfolio/Link/02.jpg"
                        alt="Portfolio-images"
                      />
                    </a>
                  </div>
                  <div className="inner">
                    <h4>
                      <a href="/portfolio-details-branding" style={{color: "white"}}>Branding</a>
                    </h4>
                    <span className="category" style={{color: "white"}}>Technique</span>
                  </div>
                </div>
              </div>
              {/* End Single Portfolio */}
              {/* Start Single Portfolio */}
              <div className="col-lg-6 col-md-6 col-12">
                <div className="related-work text-center mt--30">
                  <div className="thumb">
                    <a href="/portfolio-details-photography">
                      <img
                        src="/assets/images/portfolio/Link/04.jpg"
                        alt="Portfolio-images"
                      />
                    </a>
                  </div>
                  <div className="inner">
                    <h4>
                      <a href="/portfolio-details-photography" style={{color: "white"}}>Photography</a>
                    </h4>
                    <span className="category" style={{color: "white"}}>Technique</span>
                  </div>
                </div>
              </div>
              {/* End Single Portfolio */}
            </div>
          </div>
        </div>
        <div>
         
         <div className="watsapp ">
           <WhatsAppWidget companyName="Colourstreak"  message='Hello! 👋🏼 \n\n What can we do for you?... please mention your name bellow' phoneNumber="7980605030" />
         </div>
         </div>
        {/* End Related Work */}

        {/* Start Back To Top */}
        <div className="backto-top  pb_md">
          <ScrollToTop showUnder={160}
          style={{
            position: 'fixed',
            bottom: 91,
            right: 32,
            cursor: 'pointer',
            transitionDuration: '0.2s',
            transitionTimingFunction: 'linear',
            transitionDelay: '0s',
            background: '#fd4766'
          }}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
        </div>
      </React.Fragment>
    );
  }
}
export default PortfolioEventManagement;
