import React, { Component } from "react";
import { FiSend } from "react-icons/fi";

import { Link } from 'react-router-dom';
class About extends Component{
    render(){
        let title = 'Refreshingly Unique Company About.',
        description = 'Enthusiastic designer, having experience of more than 10+ years in graphics, logo, branding, Business advertisement, Digital advertisements, website design & Apps Design. We love the simplicity and provide our customers with 100% customer satisfaction through our designs. Come let us work together and make your imagination come true through our vision.',
        description2 = 'A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences';

        return(
            <React.Fragment>
                <div className="about-wrapper active-dark bg_color--10">
                    <div className="container">
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    <img className="w-100" src="/assets/images/about/about-1.jpg" alt="About Images"/>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        {/* <div className="icon"> */}
                                            {/* <FiSend /> */}
                                        {/* </div> */}
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                        <p className="description"></p>
                                        {/* <div className="purchase-btn">
                                            <Link className="btn-transparent" to="/">PURCHASE IMROZ</Link>
                                        </div> */}
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;