import React from 'react';
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";

export default function WatsappWidget() {
  return (
    <div >
    <div className="wat">
      <WhatsAppWidget
        companyName="Colourstreak"
        message="Hello! 👋🏼 \n\n What can we do for you?... please mention your name bellow"
        phoneNumber="7980605030"
        
      />
    </div>
  </div>
  )
}
