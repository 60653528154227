import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
class Testimonial extends Component{
    render(){
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-12">
                        <Tabs>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Understood exactly what I needed. Beautiful design created from scratch just based on the idea I had.</p>
                                               
                                            </div>
                                            <div className="author-info">
                                                <h6><span>tgravesbn</span> - 99designs</h6>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>The concept was conveyed in the design, perfectly. We appreciate the work, design and professionalism.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>mrskatiefrencX</span> - 99designs</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Very attentive and active. They gave me many options, listened to feedback, and adjusted to things that I liked the most. I went with their design in my contest because I could tell they would deliver a top notch final product.  </p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>BecomingCrystalline  </span> - 99designs</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Super responsive, quick edits, and new ideas.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>TinnCannKelsey </span> - 99designs</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>It was great. Gave multiple design ideas, all were to what I requested in my brief and made any adjustments I requested. Great work!</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>brockie_9 </span> - 99designs</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Colourstreak was able to promptly conceptualize a very fitting design based on the brief given with my desired requests. Can highly recommend. Thank you again! </p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>keckeis.romano </span> - 99designs</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Fantastic work. Very easy to work with. Look forward to using this design for a very long time. Thanks!</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>8StG7O </span> - 99designs</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Great designs, offered up multiple examples that helped me key in on what I really wanted. I was pleased with his responsiveness and willingness to adjust. </p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>chrisfreeman8338 </span> - 99designs</h6>
                                            </div>
                                        </div>
                                    </TabPanel>

                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Colourstreak came up with a truly unique and eye-catching design for my podcast. This designer was super easy to work with, took feedback well and crafted the perfect image for my new podcast. I couldn't be happier!</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>kristiL3 </span> - 99designs</h6>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Always a pleasure working with SColourstreak, thanks for the continued great work and service!</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Cole Beauty </span> - 99designs</h6>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>Excellent work & delivered exactly what I needed.</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>Plethora7 </span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="rn-testimonial-content text-left">
                                            <div className="inner">
                                                <p>I had a very good experience with designer Colourstreak. The work was beautiful and I had given a few tweaks which were not in my original brief, so it all worked out very well indeed. I would highly recommend the work Colourstreak did for me. Thank you!</p>
                                            </div>
                                            <div className="author-info">
                                                <h6><span>juli9G </span></h6>
                                            </div>
                                        </div>
                                    </TabPanel>
                                </div>
                                <div className="col-lg-6 mt_md--40 mt_sm--40">
                                    <TabList className="testimonial-thumb-wrapper">
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/about/01.jpg" alt="Testimonial Images" />
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/about/02.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        {/* <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/about/03.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab> */}
                                        {/* <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/about/04.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab> */}
                                        {/* <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/about/05.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab> */}
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/about/06.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/about/07.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                        <Tab>
                                            <div className="testimonial-thumbnai">
                                                <div className="thumb">
                                                    <img src="/assets/images/about/8.jpg" alt="Testimonial Images"/>
                                                </div>
                                            </div>
                                        </Tab>
                                    </TabList>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default Testimonial;