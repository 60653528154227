import React, { Component } from "react";
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
  FiChevronUp,
  FiMail,
  FiCopy,
  FiBox,
  FiCamera
} from "react-icons/fi";
import "./styles.css";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

const ServiceList = [
  {
    icon: <FiCast />,
    title: "Branding",
    description:
      "We combine design expertise with the help of technology and intelligence to transform your business.",
  },
  {
    icon: <FiLayers />,
    title: "Web Design",
    description:
      "We create lively websites that will help you connect easily with your customers.",
  },
  {
    icon: <FiUsers />,
    title: "Social Media",
    description:
      "We will develop your social media so that you can easily get your desired customers on a daily basis.",
  },
  {
    icon: <FiBox />,
    title: "Packaging",
    description:
      "By providing you with creative and effective packaging strategies, we will help you achieve your desired goals.",
  },
  {
    icon: <FiCamera />,
    title: "PhotoShoot & videos",
    description:
      "We do all kinds of photoshoots and videos for our clients as per their needs.",
  },
  {
    icon: <FiCopy />,
    title: "Logos",
    description:
      "We make logo design in an effective way so that your customers know about your existence.",
  },
];

class Service extends Component {
  render() {
    return (
      <React.Fragment>
         <div className="active-dark bg_color--10">
        <PageHelmet pageTitle="Service" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <Breadcrumb title={"Service"} />
        {/* End Breadcrump Area */}

        {/* Start Service Area */}
        <div className="service-area active-dark bg_color--10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <br></br>
                  <span className="subtitle">Our Services</span>
                  <p>The Services Provided By Us</p>
                </div>
              </div>
            </div>
            <div className="row service-main-wrapper">
              {ServiceList.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  <div className="service service__style--2 text-left">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div>
          <div className="watsapp ">
            <WhatsAppWidget
              companyName="Colourstreak"
              message="Hello! 👋🏼 \n\n What can we do for you?... please mention your name bellow"
              phoneNumber="7980605030"
            />
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}
          style={{
            position: 'fixed',
            bottom: 91,
            right: 32,
            cursor: 'pointer',
            transitionDuration: '0.2s',
            transitionTimingFunction: 'linear',
            transitionDelay: '0s',
            background: '#fd4766'
          }}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
        </div>
      </React.Fragment>
    );
  }
}
export default Service;
