import React, { Component } from "react";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiBox,
  FiMail,
  FiCopy,
  FiAlertCircle,
  FiCamera
} from "react-icons/fi";


const ServiceList = [
    {
        icon: <FiCast />,
        title: "Branding",
        description:
          "We combine design expertise with the help of technology and intelligence to transform your business.",
      },
      {
        icon: <FiLayers />,
        title: "Web Design",
        description:
          "We create lively websites that will help you connect easily with your customers.",
      },
      {
        icon: <FiUsers />,
        title: "Social Media",
        description:
          "We will develop your social media so that you can easily get your desired customers on a daily basis.",
      },
      {
        icon: <FiBox />,
        title: "Packaging",
        description:
          "By providing you with creative and effective packaging strategies, we will help you achieve your desired goals.",
      },
      {
        icon: <FiCamera />,
        title: "PhotoShoot & videos",
        description:
          "We do all kinds of photoshoots and videos for our clients as per their needs.",
      },
      {
        icon: <FiCopy />,
        title: "Logos",
        description:
          "We make logo design in an effective way so that your customers know about your existence.",
      },
];

class ServiceTwo extends Component {
  render() {
    let title = "Services provide for you.",
       description =
         "We bring our diverse background of branding, web design, social media, packaging, photoshoot & videos, <br /> logos to work for your company. Not only will your materials look great – they will get results.",
         
      subtitle = "What we can do for you";
    return (
      <React.Fragment>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center">
                <span className="subtitle">{subtitle}</span>
                <h2 className="title">{title}</h2>
                <p
                  className="description"
                  dangerouslySetInnerHTML={{ __html: description }}
                ></p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-12 mt--30">
              <div className="row service-main-wrapper">
                {ServiceList.map((val, i) => (
                  <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                   
                      <div className="service service__style--2 text-left">
                        <div className="icon">{val.icon}</div>
                        <div className="content">
                          <h3 className="title">{val.title}</h3>
                          <p>{val.description}</p>
                        </div>
                      </div>
                  
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceTwo;
