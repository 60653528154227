import React, { Component } from "react";
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import Testimonial from "../elements/Testimonial";
import BrandTwo from "../elements/BrandTwo";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import "./styles.css"
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import AboutComp from "../component/HomeLayout/homeOne/About";
import { Background } from "react-parallax";

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="active-dark bg_color--10">
        <PageHelmet pageTitle="About" />

<Header
  headertransparent="header--transparent"
  colorblack="color--black"
  logoname="logo.png"
/>
{/* Start Breadcrump Area */}
<Breadcrumb title={"About"} />
{/* End Breadcrump Area */}

{/* Start About Area */}
<div className="about-area ptb--120 bg_color--1">
  <AboutComp />
</div>
{/* End About Area */}

{/* Start CounterUp Area */}
<div className="rn-counterup-area ptb--120 bg_color--5">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title text-center">
          <span className="subtitle">Experts growts</span>
          <h2 className="title">Our Company Growth</h2>
          <p className="description">
            We have grown strength over the past 8 years
          </p>
        </div>
      </div>
    </div>
    <CounterOne />
  </div>
</div>
{/* End CounterUp Area */}

{/* Start Finding Us Area  */}
<div
  className="rn-finding-us-area attacment-fixed rn-finding-us ptb--120 bg_color--1 bg_image bg_image--18"
  data-black-overlay="5"
>
  <div className="container">
    <div className="row">
      <div className="col-lg-8 offset-lg-2">
        <div className="inner">
          <div className="content-wrapper">
            <div className="content" style={{textAlign:"center"}}>
              <h4 className="theme-gradient">Find Our New Office</h4>
              <p>
            140 , Sri Arabinda Sarani, Shantiniketan Apartment , Kolkata, West Bengal 700006
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
{/* End Finding Us Area  */}

{/* Start Team Area  */}
{/* <div className="rn-team-area bg_color--1 ptb--120">
  <div className="container">
    <div className="row">
      <div className="col-lg-12">
        <div className="section-title text-center mb--25">
          <span className="subtitle">Entire team</span>
          <h2 className="title">Entire team</h2>
          <p className="description">
          We're a team of skilled professionals,  <br /> always striving for the best solution. We think big, and make clients successful
          </p>
        </div>
      </div>
    </div>
    <div className="row">
 
      <div className="col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="team">
          <div className="thumbnail">
            <img
              className="w-100"
              src="/assets/images/team/team-01.jpg"
              alt="Blog Images"
            />
          </div>
          <div className="content">
            <h4 className="title">Suman Manna</h4>
            <p className="designation">Founder</p>
          </div>
          <ul className="social-icon">
            <li>
              <a href="https://www.facebook.com/">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="http://linkedin.com/">
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/">
                <FaTwitter />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="team">
          <div className="thumbnail">
            <img
              className="w-100"
              src="/assets/images/team/team-02.jpg"
              alt="Blog Images"
            />
          </div>
          <div className="content">
            <h4 className="title">Arabinda Das</h4>
            <p className="designation">Design Manager</p>
          </div>
          <ul className="social-icon">
            <li>
              <a href="https://www.facebook.com/">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="http://linkedin.com/">
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/">
                <FaTwitter />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="team">
          <div className="thumbnail">
            <img
              className="w-100"
              src="/assets/images/team/team-13.jpg"
              alt="Blog Images"
            />
          </div>
          <div className="content">
            <h4 className="title">Prosenjit Maity</h4>
            <p className="designation">Operational Manager</p>
          </div>
          <ul className="social-icon">
            <li>
              <a href="https://www.facebook.com/">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="http://linkedin.com/">
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/">
                <FaTwitter />
              </a>
            </li>
          </ul>
        </div>
      </div>

    </div>

    <div className="row">

      <div className="col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="team">
          <div className="thumbnail">
            <img
              className="w-100"
              src="/assets/images/team/team-09.jpg"
              alt="Blog Images"
            />
          </div>
          <div className="content">
            <h4 className="title">Shubasis Das</h4>
            <p className="designation">Sr. Graphics Designer</p>
          </div>
          <ul className="social-icon">
            <li>
              <a href="https://www.facebook.com/">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="http://linkedin.com/">
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/">
                <FaTwitter />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="team">
          <div className="thumbnail">
            <img
              className="w-100"
              src="/assets/images/team/team-06.jpg"
              alt="Blog Images"
            />
          </div>
          <div className="content">
            <h4 className="title">Sanjukta Jana </h4>
            <p className="designation">Podcast Artist </p>
          </div>
          <ul className="social-icon">
            <li>
              <a href="https://www.facebook.com/">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="http://linkedin.com/">
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/">
                <FaTwitter />
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="team">
          <div className="thumbnail">
            <img
              className="w-100"
              src="/assets/images/team/team-05.jpg"
              alt="Blog Images"
            />
          </div>
          <div className="content">
            <h4 className="title">Debanjan Pal</h4>
            <p className="designation">Production Artist</p>
          </div>
          <ul className="social-icon">
            <li>
              <a href="https://www.facebook.com/">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="http://linkedin.com/">
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/">
                <FaTwitter />
              </a>
            </li>
          </ul>
        </div>
      </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="team">
          <div className="thumbnail">
            <img
              className="w-100"
              src="/assets/images/team/team-07.jpg"
              alt="Blog Images"
            />
          </div>
          <div className="content">
            <h4 className="title">Raja Majumdar </h4>
            <p className="designation">Jr. Graphics Designer</p>
          </div>
          <ul className="social-icon">
            <li>
              <a href="https://www.facebook.com/">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="http://linkedin.com/">
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/">
                <FaTwitter />
              </a>
            </li>
          </ul>
        </div>
      </div>

         <div className="col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="team">
          <div className="thumbnail">
            <img
              className="w-100"
              src="/assets/images/team/team-10.jpg"
              alt="Blog Images"
            />
          </div>
          <div className="content">
            <h4 className="title">Hirak Manna </h4>
            <p className="designation">Asst. Field Executive </p>
          </div>
          <ul className="social-icon">
            <li>
              <a href="https://www.facebook.com/">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="http://linkedin.com/">
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/">
                <FaTwitter />
              </a>
            </li>
          </ul>
        </div>
      </div>
         <div className="col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="team">
          <div className="thumbnail">
            <img
              className="w-100"
              src="/assets/images/team/team-08.jpg"
              alt="Blog Images"
            />
          </div>
          <div className="content">
            <h4 className="title">Sukanta Maji</h4>
            <p className="designation">Field Supervisor</p>
          </div>
          <ul className="social-icon">
            <li>
              <a href="https://www.facebook.com/">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="http://linkedin.com/">
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/">
                <FaTwitter />
              </a>
            </li>
          </ul>
        </div>
      </div>

          <div className="col-lg-4 col-md-6 col-sm-6 col-12">
        <div className="team">
          <div className="thumbnail">
            <img
              className="w-100"
              src="/assets/images/team/team-14.jpg"
              alt="Blog Images"
            />
          </div>
          <div className="content">
            <h4 className="title">Sayanti Chowdhury</h4>
            <p className="designation">Web Developer</p>
          </div>
          <ul className="social-icon">
            <li>
              <a href="https://www.facebook.com/">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a href="http://linkedin.com/">
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a href="https://twitter.com/">
                <FaTwitter />
              </a>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</div> */}

<div className="rn-testimonial-area bg_color--5 ptb--120">
  <div className="container">
    <Testimonial />
  </div>
</div>
{/* End Testimonial Area */}

{/* Start Brand Area */}
<div className="rn-brand-area bg_color--1 ptb--120">
  <div className="container">
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="section-title text-center mb--30">
          <span className="subtitle">Top clients</span>
          <h2 className="title">We worked with brands</h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-12">
        <BrandTwo />
      </div>
    </div>
  </div>
</div>

<div className="mb--30">
 
 <div className="watsapp">
   <WhatsAppWidget companyName="colourstreak"  message='Hello! 👋🏼 \n\n What can we do for you?... please mention your name bellow' phoneNumber="7980605030" />
 </div>
 </div>

<div className="backto-top">
  <ScrollToTop showUnder={160}
  style={{
    position: 'fixed',
    bottom: 91,
    right: 32,
    cursor: 'pointer',
    transitionDuration: '0.2s',
    transitionTimingFunction: 'linear',
    transitionDelay: '0s',
    background: '#fd4766'

  }}
  >
    <FiChevronUp />
  </ScrollToTop >
</div>
{/* End Back To Top */}

<Footer />
        </div>
      
      </React.Fragment>
    );
  }
}
export default About;
