import React, { Component } from "react";
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import "./styles.css"
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

class PortfolioBranding extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
           <div className="active-dark bg_color--10">
        <PageHelmet pageTitle="Branding" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 pb_md--100 pb_sm--100  bg_image bg_image--4"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">Branding</h2>
                  <p>
                    Branding is the process of giving a meaning to specific
                    organization by creating a brand in consumers minds.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Details */}
        <div className="rn-portfolio-details ptb--120 active-dark bg_color--10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="row row--35 mb--50">
                    <div className="col-lg-6">
                      <div className="inner">
                        <div className="section-title">
                          <h2 className="title">Branding</h2>
                          <p className="description mt--30">
                            Branding is the perpetual process of identifying,
                            creating, and managing the cumulative assets and
                            actions that shape the perception of a brand in
                            stakeholders’ minds. Essentially, it is the way your
                            product or service lives in the hearts and minds of
                            your customer. Branding can change how people
                            perceive your brand, it can drive new business, and
                            increase brand value.
                          </p>
                          <p className="description">
                            We take our craft seriously. We strive to do our
                            best while connecting the dots between branding,
                            digital and web presence to connect you to your
                            public. We’ll help you on the road to your success
                            with the helping hand of a true branding partner.
                          </p>
                        </div>
                        {/* <div className="portfolio-view-list d-flex flex-wrap">
                                                    <div className="port-view">
                                                        <span>Branch</span>
                                                        <h4>Ability</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Project Types</span>
                                                        <h4>Website</h4>
                                                    </div>

                                                    <div className="port-view">
                                                        <span>Program</span>
                                                        <h4>View Project</h4>
                                                    </div>
                                                </div> */}
                        <div className="portfolio-details-btn mt--30">
                          <a className="btn-default btn-border" href="/contact">
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                        <div className="inner">
                          <div className="section-title">
                            <h4 className="title">Our Services</h4>
                            {/* <p className="description mt--30">
                              We will support your brand, by developing an
                              understanding of your business, clarifying your
                              goals and objectives and communicating this in the
                              right way to the right audience
                            </p>
                            <p className="description mt--30">
                              We can help you to define your brand, your brand
                              experience and improve your overall brand
                              strategy.
                            </p> */}
                            <br />
                            <ul className="list-style--1">
                              <li>
                                <FiCheck />Store Exterior and Interior Branding
                              </li>
                              <li>
                                <FiCheck /> Company’s Name Signage
                              </li>
                              <li>
                                <FiCheck /> Kiosk
                              </li>
                              <li>
                                <FiCheck /> ACP Signage
                              </li>
                              <li>
                                <FiCheck />3D Acrylic Liquid Letter
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="portfolio-thumb-inner">
                    <div className="thumb position-relative mb--30">
                      <img
                        src="/assets/images/portfolio//Branding/01.jpg"
                        alt="Portfolio Images"
                      />
                      {/* <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button> */}
                    </div>

                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Branding/02.jpg"
                        alt="Portfolio Images"
                      />
                    </div>

                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Branding/03.jpg"
                        alt="Portfolio Images"
                      />
                    </div>

                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Branding/04.jpg"
                        alt="Portfolio Images"
                      />
                    </div>

                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Branding/05.jpg"
                        alt="Portfolio Images"
                      />
                    </div>

                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Branding/06.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Branding/07.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Branding/08.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Branding/09.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Branding/10.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Branding/11.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/Branding/12.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="thumb">
                      <img
                        src="/assets/images/portfolio/Branding/13.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Details */}

        {/* Start Related Work */}
        <div className="portfolio-related-work pb--120 active-dark bg_color--10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle">Related Work</span>
                  <h2 className="title">Our More Projects</h2>
                </div>
              </div>
            </div>
            <div className="row mt--10">
              {/* Start Single Portfolio */}
              <div className="col-lg-6 col-md-6 col-12">
                <div className="related-work text-center mt--30">
                  <div className="thumb">
                    <a href="/portfolio-details-creative">
                      <img
                        src="/assets/images/portfolio/Link/01.jpg"
                        alt="Portfolio-images"
                      />
                    </a>
                  </div>
                  <div className="inner">
                    <h4>
                      <a href="/portfolio-details-creative" style={{color: "white"}}>Graphics Design</a>
                    </h4>
                    <span className="category" style={{color: "white"}}>Technique</span>
                  </div>
                </div>
              </div>
              {/* End Single Portfolio */}
              {/* Start Single Portfolio */}
              <div className="col-lg-6 col-md-6 col-12">
                <div className="related-work text-center mt--30">
                  <div className="thumb">
                    <a href="/portfolio-details-digitalPrint">
                      <img
                        src="/assets/images/portfolio/Link/02.jpg"
                        alt="Portfolio-images"
                      />
                    </a>
                  </div>
                  <div className="inner">
                    <h4>
                      <a href="/portfolio-details-digitalPrint" style={{color: "white"}}>
                        Digital Print
                      </a>
                    </h4>
                    <span className="category" style={{color: "white"}}>PLANNING</span>
                  </div>
                </div>
              </div>
              {/* End Single Portfolio */}
            </div>
          </div>
        </div>
        <div>
         
         <div className="watsapp ">
           <WhatsAppWidget companyName="Colourstreak"  message='Hello! 👋🏼 \n\n What can we do for you?... please mention your name bellow' phoneNumber="7980605030" />
         </div>
         </div>
        {/* End Related Work */}

        {/* Start Back To Top */}
        <div className="backto-top  pb_md">
          <ScrollToTop showUnder={160}
          style={{
            position: 'fixed',
            bottom: 91,
              right: 32,
            cursor: 'pointer',
            transitionDuration: '0.2s',
            transitionTimingFunction: 'linear',
            transitionDelay: '0s',
            background: '#fd4766'
          }}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
        </div>
      </React.Fragment>
    );
  }
}
export default PortfolioBranding;
