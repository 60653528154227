import React, { Component, Fragment } from "react";
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import "./style.css";
import PortfolioMasonry from "../elements/portfolio/PortfolioMasonry";
import Header from "../component/header/Header";
import FooterTwo from "../component/footer/Footer";
import Helmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ProgressOne from "../blocks/progressbar/ProgressOne";
import ServiceTwo from "../elements/service/ServiceTwo";
import WatsappWidget from "./WatsappWidget";
import CustomPopup from "./CustomPopup";


const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "Colourstreak",
    description: "We Create Brands for Tomorrow.",
    buttonText: "Contact Us",
    buttonLink: "/contact",
  },
];

class HomePortfolio extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.state = {
      isVisible: true,
    };
    this.openModal = this.openModal.bind(this);
    this.popupCloseHandler = this.popupCloseHandler.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  
  popupCloseHandler(){
    console.log(this.state.isVisible)
    this.setState({ isVisible: false });
  }
  render() {
    return (
      <>
          {/* <CustomPopup
      onClose={this.popupCloseHandler}
      show={this.state.isVisible}
        title=""
      >
        <h4 className="title theme-gradient">Best Design Awards | DesignRush</h4>
        <p>Discover the best designs across website, logo, app, print, video, branding and marketing campaign categories worthy of best design awards. Get inspired!</p>
        <a href="https://www.designrush.com/" target="_blank">www.designrush.com</a>
      </CustomPopup> */}

        <div className="active-dark bg_color--10">
          
          <Helmet pageTitle="Colourstreak" />




          {/* Start Header Area  */}
          <Header />
          {/* End Header Area  */}

          {/* Start Slider Area   */}
          <div className="slider-wrapper">
            {/* Start Single Slide */}
            {SlideList.map((value, index) => (
              <div
                className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--24"
                key={index}
                data-black-overlay="6"
              >
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className={`inner ${value.textPosition}`}>
                        {value.category ? <span>{value.category}</span> : ""}
                        {value.title ? (
                          <h1 className="title theme-gradient">
                            {value.title}
                          </h1>
                        ) : (
                          ""
                        )}
                        {value.description ? (
                          <p className="description" style={{color: "white"}}>{value.description}</p>
                        ) : (
                          ""
                        )}
                        {value.buttonText ? (
                          <div className="slide-btn">
                            <a
                              className="btn-default btn-border btn-opacity"
                              href={`${value.buttonLink}`}
                            >
                              {value.buttonText}
                            </a>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* End Single Slide */}
          </div>
          {/* End Slider Area   */}

          {/* Start portfolio Area  */}
          <div className="rn-portfolio-area bg_color--10 ptb--120">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center mb--30">
                    <br />
                    <span className="subtitle">Goal-Oriented</span>
                    <h2 className="title">Our Projects</h2>
                    <p className="description">
                      We are focused on reaching a specific objective or
                      accomplishing a given task, <br /> Have a look of projects .
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="wrapper plr--30">
              <div className="row">
                <PortfolioMasonry
                  item="8"
                  column="col-lg-3 col-md-6 col-sm-6 col-12 portfolio-tilthover"
                />
              </div>
              {/* <div className="row">
                <div className="col-md-12">
                  <div className="view-more-btn mt--60 text-center">
                    <a className="btn-default" href="/portfolio">
                      <span>View More Project</span>
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* End portfolio Area  */}

          <div className="service-area pb--120 bg_color--10">
            <div className="container">
              <ServiceTwo />
            </div>
          </div>

          {/* Start Progress Bar Area   */}
          <div className="rn-progress-area pb--120 bg_color--10">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/about/about-4.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12 mt_sm--30">
                  <div className="progress-content-here">
                    <div className="section-title text-left">
                      <span className="subtitle">Our Skill</span>
                      <h2 className="title">we have experties on</h2>
                      <p className="description">
                        we provide various types of branding related facilities like designing, Managment, Marketing, Development to satisfy our customers.

                      </p>
                    </div>
                    <ProgressOne ProgressStyle="progress-bar--3" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End Progress Bar Area   */}

          {/* Start Brand Area */}
          {/* <div className="rn-brand-area pb--120 bg_color--10">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <span className="subtitle">Top video</span>
                  <h2 className="title">Our Clients Review</h2>
                </div>
              </div>
            </div>
            <div className="video-image-wrapper mt--30">
              <div className="container">
                <div className="row">
                  <div className="col-lg-10 offset-lg-1">
                    <div className="thumb position-relative">
                      <img
                        className="w-100"
                        src="/assets/images/blog/bl-big-02.jpg"
                        alt="Service Images"
                      />
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="ZOoVOfieAF8"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button
                        className="video-popup position-top-center theme-color"
                        onClick={this.openModal}
                      >
                        <span className="play-icon"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

          {/* End Brand Area */}

          {/* Start Footer Area  */}
          <FooterTwo />
          {/* End Footer Area  */}
          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160} style={{
              position: 'fixed',
              bottom: 91,
              right: 32,
              cursor: 'pointer',
              transitionDuration: '0.2s',
              transitionTimingFunction: 'linear',
              transitionDelay: '0s',
              background: '#fd4766'
            }}>
              <FiChevronUp />
            </ScrollToTop>
          </div>

          {/* End Back To Top */}
        </div>
        {/* <div className="mb--30">
          <div className="wat">
            <WhatsAppWidget
              companyName="Colourstreak"
              message="Hello! 👋🏼 \n\n What can we do for you?... please mention your name bellow"
              phoneNumber="7980605030"
              
            />
          </div>
        </div> */}
        <WatsappWidget />
      </>
    );
  }
}
export default HomePortfolio;
