import React, { Component } from "react";
import { Link } from 'react-router-dom';
const Portfolio_image = <img src="/assets/images/portfolio/portfolio-static-01.jpg" alt="React Creative Agency" />;
const Portfolio_image2 = <img src="/assets/images/portfolio/portfolio-static-02.jpg" alt="React Creative Agency" />;
const Portfolio_image3 = <img src="/assets/images/portfolio/portfolio-static-03.jpg" alt="React Creative Agency" />;
const Portfolio_image4 = <img src="/assets/images/portfolio/portfolio-static-04.jpg" alt="React Creative Agency" />;
const Portfolio_image5 = <img src="/assets/images/portfolio/portfolio-static-05.jpg" alt="React Creative Agency" />;
const Portfolio_image6 = <img src="/assets/images/portfolio/portfolio-static-06.jpg" alt="React Creative Agency" />;
const Portfolio_image7 = <img src="/assets/images/portfolio/portfolio-static-07.jpg" alt="React Creative Agency" />;
const Portfolio_image8 = <img src="/assets/images/portfolio/portfolio-static-08.jpg" alt="React Creative Agency" />;
const Portfolio_image9 = <img src="/assets/images/portfolio/portfolio-static-09.jpg" alt="React Creative Agency" />;
const Portfolio_image10 = <img src="/assets/images/portfolio/portfolio-static-01.jpg" alt="React Creative Agency" />;
const Portfolio_image11 = <img src="/assets/images/portfolio/portfolio-static-08.jpg" alt="React Creative Agency" />;
const Portfolio_image12 = <img src="/assets/images/portfolio/portfolio-static-05.jpg" alt="React Creative Agency" />;



const PortfolioList = [
    {
        image: Portfolio_image,
        category: 'Development',
        title: 'Graphics Design',
        description: 'Graphic design is a craft where professionals create visual content to communicate messages.',
        link : '/portfolio-details-creative'
    },
    {
        image: Portfolio_image2,
        category: 'Product Design',
        title: 'Branding',
        description: 'Branding is the process of giving a meaning to specific organization by creating a brand in consumers minds.',
        link : '/portfolio-details-branding'
    },
    {
        image: Portfolio_image3,
        category: 'Application',
        title: 'Digital Print',
        description: 'Digital printing is the process of printing digital-based images directly onto a variety of media substrates.',
        link : '#'
    },
    {
        image: Portfolio_image4,
        category: 'Application',
        title: 'Photography & videography',
        description: 'Photography is the art of capturing light with a camera via a digital sensor or film, to create an image.',
        link : '/portfolio-details-photography'
    },
    {
        image: Portfolio_image5,
        category: 'Application',
        title: 'Event Management',
        description: 'Event management involves overseeing all logistics leading up to and during an event.',
        link : '/portfolio-details-eventManagement'
    },
    {
        image: Portfolio_image6,
        category: 'Application',
        title: 'Interior',
        description: 'Interior designers make interior spaces functional, safe, and beautiful for almost every type of building.',
        // link : '/portfolio-details-interior'
    },
    {
        image: Portfolio_image7,
        category: 'Application',
        title: 'Digital Marketing',
        description: 'Digital marketing is the promotion of brands to connect with potential customers using the internet.',
        link : '/portfolio-details-digitalMarketing'
    },
    {
        image: Portfolio_image8,
        category: 'Application',
        title: 'Web Design',
        description: 'Web design refers to the design of websites that are displayed on the internet.',
        link : '/portfolio-details-web-design'
    },
    // {
    //     image: Portfolio_image9,
    //     category: 'Application',
    //     title: 'Photoshop Design',
    //     description: 'Photoshop Design refers to the design of websites that are displayed on the internet.',
    //     link : '/portfolio-details'
    // },
    {
        image: Portfolio_image10,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.',
        link : '/portfolio-details'
    },
    {
        image: Portfolio_image11,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.',
        link : '/portfolio-details'
    },
    {
        image: Portfolio_image12,
        category: 'Application',
        title: 'Photoshop Design',
        description: 'Lorem ipsum dolor sit amet, consec tetur adipiscing elit.',
        link : '/portfolio-details'
    },
]


class PortfolioMasonry extends Component{
    render(){
        const {column } = this.props;
        const list = PortfolioList.slice(0 , this.props.item);
        return(
            <React.Fragment>
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className="im_portfolio">
                            <div className="thumbnail_inner">
                                <div className="thumbnail">
                                    <Link to={value.link}>
                                        {value.image}
                                    </Link>    
                                </div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <div className="portfolio_heading my-5">
                                        {/* <div className="category_list">
                                            <Link to="/portfolio-details">{value.category}</Link>
                                        </div> */}
                                        <h4 className="title text-center"><Link to={value.link}>{value.title}</Link></h4>
                                    </div>
                                    <div className="portfolio_hover">
                                        <br></br>
                                        
                                    </div>
                                </div>
                            </div>
                            <Link className="transparent_link" to={value.link}></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioMasonry;