import React, { Component } from "react";
import WhatsAppWidget from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import {
  FaTwitter,
  FaInstagram,
  FaFacebookF,
  FaLinkedinIn,
} from "react-icons/fa";
import "./styles.css"
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { FiCheck } from "react-icons/fi";
const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/" },
  { Social: <FaTwitter />, link: "https://twitter.com/" },
];

class PortfolioWebDesign extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
           <div className="active-dark bg_color--10">
        <PageHelmet pageTitle="Web Design" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 pb_md--100 pb_sm--100  bg_image bg_image--4"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    Web Design
                  </h2>
                  <p>
                  Web design refers to the design of websites that are displayed on the internet.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Portfolio Details */}
        <div className="rn-portfolio-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="portfolio-details">
                  <div className="row row--35 mb--50">
                    <div className="col-lg-6">
                      <div className="inner">
                        <div className="section-title">
                          <h2 className="title">Web Design</h2>
                          <p className="description mt--30">
                            Web design is the process of planning,
                            conceptualizing and building websites. The designer
                            determines the layout and structure, images, font
                            and font styles, colors, graphics, and interactive
                            features, which in combination, helps make your
                            website looks credible and professional in the eyes
                            of your visitors
                          </p>
                          <p className="description">
                            While the terms web design and web development are
                            used interchangeably, web design is still a subset
                            of web development (which is a broader process)
                          </p>
                        </div>
                        <div className="portfolio-details-btn mt--30">
                          <a className="btn-default btn-border" href="/contact">
                            Contact Us
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="portfolio-details-accordion mt_md--40 mt_sm--40">
                        <div className="inner">
                          <div className="section-title">
                            <h4 className="title">Our services</h4>
                            {/* <p className="description mt--30">
                            Web designers build webpages using HTML tags (a markup language that is used to build websites.
                            </p>
                            <p className="description mt--30">
                            The page’s layout is defined using CSS (cascading style sheets).
                            </p> */}
                            <ul className="list-style--1">
                              <li>
                                <FiCheck /> Website Development
                              </li>
                              <li>
                                <FiCheck /> Website Maintenance
                              </li>
                              <li>
                                <FiCheck /> Applications
                              </li>
                              <li>
                                <FiCheck /> E-commerce Development
                              </li>
                              <li>
                                <FiCheck /> Hosting
                              </li>
                              <li>
                                <FiCheck /> Logo Design
                              </li>
                              <li>
                                <FiCheck /> SSL Certificates
                              </li>
                              <li>
                                <FiCheck /> Domains
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="portfolio-thumb-inner">
                    {/* <div className="thumb position-relative mb--30">
                                            <img src="/assets/images/portfolio/portfolio-big-03.jpg" alt="Portfolio Images"/>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div> */}

                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/portfolio-big-32.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="thumb mb--30">
                      <img
                        src="/assets/images/portfolio/portfolio-big-33.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                    <div className="thumb ">
                      <img
                        src="/assets/images/portfolio/portfolio-big-34.jpg"
                        alt="Portfolio Images"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Portfolio Details */}

        {/* Start Related Work */}
        <div className="portfolio-related-work pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle">Related Work</span>
                  <h2 className="title">Our More Projects</h2>
                </div>
              </div>
            </div>
            <div className="row mt--10">
              {/* Start Single Portfolio */}
              <div className="col-lg-6 col-md-6 col-12">
                <div className="related-work text-center mt--30">
                  <div className="thumb">
                    <a href="/portfolio-details-photography">
                      <img
                        src="/assets/images/portfolio/Link/04.jpg"
                        alt="Portfolio-images"
                      />
                    </a>
                  </div>
                  <div className="inner">
                    <h4>
                      <a href="/portfolio-details-photography">Photography</a>
                    </h4>
                    <span className="category">Technique</span>
                  </div>
                </div>
              </div>
              {/* End Single Portfolio */}
              {/* Start Single Portfolio */}
              <div className="col-lg-6 col-md-6 col-12">
                <div className="related-work text-center mt--30">
                  <div className="thumb">
                    <a href="/portfolio-details-creative">
                      <img
                        src="/assets/images/portfolio/Link/01.jpg"
                        alt="Portfolio-images"
                      />
                    </a>
                  </div>
                  <div className="inner">
                    <h4>
                      <a href="/portfolio-details-creative">Graphics Design</a>
                    </h4>
                    <span className="category">Technique</span>
                  </div>
                </div>
              </div>
              {/* End Single Portfolio */}
            </div>
          </div>
        </div>
        <div className="mb--30">
         
         <div className="watsapp ">
           <WhatsAppWidget companyName="Colourstreak"  message='Hello! 👋🏼 \n\n What can we do for you?... please mention your name bellow' phoneNumber="7980605030" />
         </div>
         </div>
        {/* End Related Work */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}
          style={{
            position: 'fixed',
            bottom: 91,
            right: 32,
            cursor: 'pointer',
            transitionDuration: '0.2s',
            transitionTimingFunction: 'linear',
            transitionDelay: '0s',
            background: '#fd4766'
          }}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
        </div>
      </React.Fragment>
    );
  }
}
export default PortfolioWebDesign;
